import { FetchContext } from "ofetch";

export default defineNuxtPlugin((nuxtApp) => {
  const state = useGraphqlState()

  if (!state) {
    return
  }

  const token = useCookie('graphql:token');

  state.fetchOptions = {
    async onRequest({ request, options }: FetchContext) {
      if (token.value) {
        options.headers = new Headers({
          'Authorization': `Bearer ${token.value}`,
        });
      }
    },
  }
});
