import validate from "/home/runner/work/joysticktv-office/joysticktv-office/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/joysticktv-office/joysticktv-office/middleware/auth.global.ts";
import menu_45counts_45global from "/home/runner/work/joysticktv-office/joysticktv-office/middleware/menuCounts.global.ts";
import manifest_45route_45rule from "/home/runner/work/joysticktv-office/joysticktv-office/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  menu_45counts_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}