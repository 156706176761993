import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig> {
  scrollBehaviorType: 'smooth',
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Return saved scroll position if available
        if (savedPosition) {
          resolve(savedPosition);
        }

        // Scroll to top
        resolve({ top: 0 });
      }, 100)
    })
  },
}
