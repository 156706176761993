import { default as _91id_93BUzk4YzUegMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/app-settings/[id].vue?macro=true";
import { default as indexoqZy9G8XOjMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/app-settings/index.vue?macro=true";
import { default as indexnMRj65Aa0YMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/arrests/index.vue?macro=true";
import { default as jail5rgQYg6cnVMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/arrests/jail.vue?macro=true";
import { default as loginaiGaU3EgjXMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/auth/login.vue?macro=true";
import { default as logoutidOfStCTNrMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/auth/logout.vue?macro=true";
import { default as _91id_93WZuch5MHwMMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/bot-applications/[id].vue?macro=true";
import { default as indexWV1AJBL3W0Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/bot-applications/index.vue?macro=true";
import { default as _91id_93Y8BEoNyZHxMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/conversations/[id].vue?macro=true";
import { default as _91id_93QWRPlc94H6Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/fingerprints/[id].vue?macro=true";
import { default as index22rjNlhxajMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/fingerprints/index.vue?macro=true";
import { default as indexHpZ5AI20q4Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/index.vue?macro=true";
import { default as indexjW6DNPGznHMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/media/index.vue?macro=true";
import { default as reviewtHmP1ezpXSMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/media/review.vue?macro=true";
import { default as _91id_93s7kGDmsgxmMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/payment-approvals/[id].vue?macro=true";
import { default as indexHCQMuKXqQRMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/payment-approvals/index.vue?macro=true";
import { default as _91id_939xAQkrrC1MMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/payouts/[id].vue?macro=true";
import { default as index8A7myOVKO3Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/payouts/index.vue?macro=true";
import { default as indexV6JPQzUdWKMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/payouts/view/[method]/[[date]]/index.vue?macro=true";
import { default as _91id_93CAUT8ekfroMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/postbacks/[id].vue?macro=true";
import { default as indexDskbQCzw2CMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/postbacks/index.vue?macro=true";
import { default as affiliates2yD0XVdafxMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/affiliates.vue?macro=true";
import { default as ballers1bY4df90xeMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/ballers.vue?macro=true";
import { default as indexn0NPj1VptrMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/index.vue?macro=true";
import { default as media_45transcodingtYtyvmosxmMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/media-transcoding.vue?macro=true";
import { default as past_45money_45makersu3gwjo8M6GMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/past-money-makers.vue?macro=true";
import { default as plausiblejALWHtPQZ5Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/plausible.vue?macro=true";
import { default as streamer_45location_45map4UOgwTTPCHMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/streamer-location-map.vue?macro=true";
import { default as taxesJTEQmTMfsMMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/taxes.vue?macro=true";
import { default as signups19k46noVKmMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/users/signups.vue?macro=true";
import { default as indexQqXPpZ97KDMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/restricted-domains/index.vue?macro=true";
import { default as index1AWtawCL1hMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/send-notification/index.vue?macro=true";
import { default as _91id_93MohQa7VHFPMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/stream-approvals/[id].vue?macro=true";
import { default as index4TfiR4K4ifMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/stream-approvals/index.vue?macro=true";
import { default as indexG7S4hkyS2PMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/streaming-servers/index.vue?macro=true";
import { default as index1uCIbzH9hnMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/streams/index.vue?macro=true";
import { default as indexBbKmFNHyp5Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/subscriptions/index.vue?macro=true";
import { default as indexzomAez6NcnMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/tag-approvals/index.vue?macro=true";
import { default as _91id_93oEE4HoSWSZMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/transactions/[id].vue?macro=true";
import { default as indexIgHVG8obLsMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/transactions/index.vue?macro=true";
import { default as affiliate_45referrals1f2lOCRTF4Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/affiliate-referrals.vue?macro=true";
import { default as approvals_45invitesGxUj9orr9lMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/approvals-invites.vue?macro=true";
import { default as biller_45accountsXVu5tTU6M3Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/biller-accounts.vue?macro=true";
import { default as blocked_45by6bRsFz4t64Meta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/blocked-by.vue?macro=true";
import { default as blocked_45usersjpvDIwPQNXMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/blocked-users.vue?macro=true";
import { default as chat_45messagesR2EdOd1bvzMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/chat-messages.vue?macro=true";
import { default as conversations7JVjw0Fj5YMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/conversations.vue?macro=true";
import { default as followersvszAgR0yZfMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/followers.vue?macro=true";
import { default as followingrpvy6zFKlQMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/following.vue?macro=true";
import { default as indexBhmCViOwToMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/index.vue?macro=true";
import { default as loginsiUbe1VJtyDMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/logins.vue?macro=true";
import { default as social_45links5tAPThBbrKMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/social-links.vue?macro=true";
import { default as subscribersKLbIIVFFxwMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/subscribers.vue?macro=true";
import { default as subscriptionsFv3pkDaH5NMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/subscriptions.vue?macro=true";
import { default as indexGOBgTaWN8SMeta } from "/home/runner/work/joysticktv-office/joysticktv-office/pages/users/index.vue?macro=true";
export default [
  {
    name: "app-settings-id",
    path: "/app-settings/:id()",
    meta: _91id_93BUzk4YzUegMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/app-settings/[id].vue").then(m => m.default || m)
  },
  {
    name: "app-settings",
    path: "/app-settings",
    meta: indexoqZy9G8XOjMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/app-settings/index.vue").then(m => m.default || m)
  },
  {
    name: "arrests",
    path: "/arrests",
    meta: indexnMRj65Aa0YMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/arrests/index.vue").then(m => m.default || m)
  },
  {
    name: "arrests-jail",
    path: "/arrests/jail",
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/arrests/jail.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginaiGaU3EgjXMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "bot-applications-id",
    path: "/bot-applications/:id()",
    meta: _91id_93WZuch5MHwMMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/bot-applications/[id].vue").then(m => m.default || m)
  },
  {
    name: "bot-applications",
    path: "/bot-applications",
    meta: indexWV1AJBL3W0Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/bot-applications/index.vue").then(m => m.default || m)
  },
  {
    name: "conversations-id",
    path: "/conversations/:id()",
    meta: _91id_93Y8BEoNyZHxMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/conversations/[id].vue").then(m => m.default || m)
  },
  {
    name: "fingerprints-id",
    path: "/fingerprints/:id()",
    meta: _91id_93QWRPlc94H6Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/fingerprints/[id].vue").then(m => m.default || m)
  },
  {
    name: "fingerprints",
    path: "/fingerprints",
    meta: index22rjNlhxajMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/fingerprints/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexHpZ5AI20q4Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "media",
    path: "/media",
    meta: indexjW6DNPGznHMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: "media-review",
    path: "/media/review",
    meta: reviewtHmP1ezpXSMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/media/review.vue").then(m => m.default || m)
  },
  {
    name: "payment-approvals-id",
    path: "/payment-approvals/:id()",
    meta: _91id_93s7kGDmsgxmMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/payment-approvals/[id].vue").then(m => m.default || m)
  },
  {
    name: "payment-approvals",
    path: "/payment-approvals",
    meta: indexHCQMuKXqQRMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/payment-approvals/index.vue").then(m => m.default || m)
  },
  {
    name: "payouts-id",
    path: "/payouts/:id()",
    meta: _91id_939xAQkrrC1MMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/payouts/[id].vue").then(m => m.default || m)
  },
  {
    name: "payouts",
    path: "/payouts",
    meta: index8A7myOVKO3Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "payouts-view-method-date",
    path: "/payouts/view/:method()/:date?",
    meta: indexV6JPQzUdWKMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/payouts/view/[method]/[[date]]/index.vue").then(m => m.default || m)
  },
  {
    name: "postbacks-id",
    path: "/postbacks/:id()",
    meta: _91id_93CAUT8ekfroMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/postbacks/[id].vue").then(m => m.default || m)
  },
  {
    name: "postbacks",
    path: "/postbacks",
    meta: indexDskbQCzw2CMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/postbacks/index.vue").then(m => m.default || m)
  },
  {
    name: "reporting-affiliates",
    path: "/reporting/affiliates",
    meta: affiliates2yD0XVdafxMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/affiliates.vue").then(m => m.default || m)
  },
  {
    name: "reporting-ballers",
    path: "/reporting/ballers",
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/ballers.vue").then(m => m.default || m)
  },
  {
    name: "reporting",
    path: "/reporting",
    meta: indexn0NPj1VptrMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/index.vue").then(m => m.default || m)
  },
  {
    name: "reporting-media-transcoding",
    path: "/reporting/media-transcoding",
    meta: media_45transcodingtYtyvmosxmMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/media-transcoding.vue").then(m => m.default || m)
  },
  {
    name: "reporting-past-money-makers",
    path: "/reporting/past-money-makers",
    meta: past_45money_45makersu3gwjo8M6GMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/past-money-makers.vue").then(m => m.default || m)
  },
  {
    name: "reporting-plausible",
    path: "/reporting/plausible",
    meta: plausiblejALWHtPQZ5Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/plausible.vue").then(m => m.default || m)
  },
  {
    name: "reporting-streamer-location-map",
    path: "/reporting/streamer-location-map",
    meta: streamer_45location_45map4UOgwTTPCHMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/streamer-location-map.vue").then(m => m.default || m)
  },
  {
    name: "reporting-taxes",
    path: "/reporting/taxes",
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/taxes.vue").then(m => m.default || m)
  },
  {
    name: "reporting-users-signups",
    path: "/reporting/users/signups",
    meta: signups19k46noVKmMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/reporting/users/signups.vue").then(m => m.default || m)
  },
  {
    name: "restricted-domains",
    path: "/restricted-domains",
    meta: indexQqXPpZ97KDMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/restricted-domains/index.vue").then(m => m.default || m)
  },
  {
    name: "send-notification",
    path: "/send-notification",
    meta: index1AWtawCL1hMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/send-notification/index.vue").then(m => m.default || m)
  },
  {
    name: "stream-approvals-id",
    path: "/stream-approvals/:id()",
    meta: _91id_93MohQa7VHFPMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/stream-approvals/[id].vue").then(m => m.default || m)
  },
  {
    name: "stream-approvals",
    path: "/stream-approvals",
    meta: index4TfiR4K4ifMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/stream-approvals/index.vue").then(m => m.default || m)
  },
  {
    name: "streaming-servers",
    path: "/streaming-servers",
    meta: indexG7S4hkyS2PMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/streaming-servers/index.vue").then(m => m.default || m)
  },
  {
    name: "streams",
    path: "/streams",
    meta: index1uCIbzH9hnMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/streams/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    meta: indexBbKmFNHyp5Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "tag-approvals",
    path: "/tag-approvals",
    meta: indexzomAez6NcnMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/tag-approvals/index.vue").then(m => m.default || m)
  },
  {
    name: "transactions-id",
    path: "/transactions/:id()",
    meta: _91id_93oEE4HoSWSZMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/transactions/[id].vue").then(m => m.default || m)
  },
  {
    name: "transactions",
    path: "/transactions",
    meta: indexIgHVG8obLsMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-affiliate-referrals",
    path: "/users/:id()/affiliate-referrals",
    meta: affiliate_45referrals1f2lOCRTF4Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/affiliate-referrals.vue").then(m => m.default || m)
  },
  {
    name: "users-id-approvals-invites",
    path: "/users/:id()/approvals-invites",
    meta: approvals_45invitesGxUj9orr9lMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/approvals-invites.vue").then(m => m.default || m)
  },
  {
    name: "users-id-biller-accounts",
    path: "/users/:id()/biller-accounts",
    meta: biller_45accountsXVu5tTU6M3Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/biller-accounts.vue").then(m => m.default || m)
  },
  {
    name: "users-id-blocked-by",
    path: "/users/:id()/blocked-by",
    meta: blocked_45by6bRsFz4t64Meta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/blocked-by.vue").then(m => m.default || m)
  },
  {
    name: "users-id-blocked-users",
    path: "/users/:id()/blocked-users",
    meta: blocked_45usersjpvDIwPQNXMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/blocked-users.vue").then(m => m.default || m)
  },
  {
    name: "users-id-chat-messages",
    path: "/users/:id()/chat-messages",
    meta: chat_45messagesR2EdOd1bvzMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/chat-messages.vue").then(m => m.default || m)
  },
  {
    name: "users-id-conversations",
    path: "/users/:id()/conversations",
    meta: conversations7JVjw0Fj5YMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/conversations.vue").then(m => m.default || m)
  },
  {
    name: "users-id-followers",
    path: "/users/:id()/followers",
    meta: followersvszAgR0yZfMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/followers.vue").then(m => m.default || m)
  },
  {
    name: "users-id-following",
    path: "/users/:id()/following",
    meta: followingrpvy6zFKlQMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/following.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexBhmCViOwToMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-logins",
    path: "/users/:id()/logins",
    meta: loginsiUbe1VJtyDMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/logins.vue").then(m => m.default || m)
  },
  {
    name: "users-id-social-links",
    path: "/users/:id()/social-links",
    meta: social_45links5tAPThBbrKMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/social-links.vue").then(m => m.default || m)
  },
  {
    name: "users-id-subscribers",
    path: "/users/:id()/subscribers",
    meta: subscribersKLbIIVFFxwMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/subscribers.vue").then(m => m.default || m)
  },
  {
    name: "users-id-subscriptions",
    path: "/users/:id()/subscriptions",
    meta: subscriptionsFv3pkDaH5NMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/[id]/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexGOBgTaWN8SMeta || {},
    component: () => import("/home/runner/work/joysticktv-office/joysticktv-office/pages/users/index.vue").then(m => m.default || m)
  }
]