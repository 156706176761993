import { useUserStore } from '@/store/user';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.meta.skipAuth) return;

  const userStore = useUserStore();
  const loggedIn = await userStore.loggedIn();

  if (!loggedIn && to.path !== '/auth/login') {
    return navigateTo('/auth/login');
  }

  if (loggedIn && to.meta.noAuth) {
    return abortNavigation();
  }

  if (userStore.user && !userStore.user.admin) {
    userStore.logOut();
    return navigateTo('/auth/login');
  }
});
