export default defineAppConfig({
  ui: {
    primary: "russian-violet",
    secondary: "harvest-gold",
    table: {
      tr: {
        base: "even:bg-taupe-gray-100 dark:even:bg-taupe-gray-950 border-none",
      }
    },
    card: {
      strategy: "override",
      background: 'bg-white dark:bg-taupe-gray-950',
      divide: 'divide-y divide-gray-200 dark:divide-taupe-gray-900',
      ring: 'ring-1 ring-black-50 dark:ring-taupe-gray-900',
    },
    input: {
      color: {
        white: {
          outline: "bg-taupe-gray-50 dark:bg-black-900 ring-1 ring-inset ring-taupe-gray-100 dark:ring-black-700 focus:ring-2",
        }
      }
    },
    modal: {
      container: "items-center"
    },
    notifications: {
      position: "bottom-auto top-0 right-0",
    },
  },
});
