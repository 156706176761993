import { defineStore } from 'pinia';

export interface AppState {
  mediaForReviewCount: number;
  streamApprovalsForReviewCount: number;
  tagsReviewCount: number;
  paymentsReviewCount: number;
  globalSearch: string;
}

export const useAppStore = defineStore('app', {
    state: (): AppState => ({
      mediaForReviewCount: 0,
      streamApprovalsForReviewCount: 0,
      tagsReviewCount: 0,
      paymentsReviewCount: 0,
      globalSearch: '',
    }),

    getters: {
      hasMediaForReview: (state: AppState) => state.mediaForReviewCount > 0,
      hasStreamApprovalsForReview: (state: AppState) => state.streamApprovalsForReviewCount > 0,
      hasTagsForReview: (state: AppState) => state.tagsReviewCount > 0,
      hasPaymentsForReview: (state: AppState) => state.paymentsReviewCount > 0
    },

    actions: {
      async refreshMediaForReviewCount() {
        const { data: mediaPending } = await useGraphqlQuery('OfficeFetchMediaPendingCount');
        this.mediaForReviewCount = parseInt(mediaPending.officeFetchMediaPendingCount);
      },

      async refreshStreamApprovalsForReviewCount() {
        const { data: streamApprovalPending } = await useGraphqlQuery('OfficeFetchStreamApprovalPendingCount');
        this.streamApprovalsForReviewCount = parseInt(streamApprovalPending.officeFetchStreamApprovalPendingCount);
      },

      async refreshTagsForReviewCount() {
        const { data: tagsPending } = await useGraphqlQuery('OfficeFetchTagsPendingCount');
        this.tagsReviewCount = parseInt(tagsPending.officeFetchTagsPendingCount);
      },

      async refreshPaymentsForReviewCount() {
        const { data: businessAccountPending } = await useGraphqlQuery('OfficeFetchBusinessAccountsPendingCount');
        this.paymentsReviewCount = parseInt(businessAccountPending.officeFetchBusinessAccountsPendingCount);
      },

      setGlobalSearch(search: string) {
        this.globalSearch = search;
      },
    },
});
