
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "graphqlMiddleware": {
    "clientCacheEnabled": false,
    "clientCacheMaxSize": 100
  },
  "ui": {
    "primary": "teal",
    "secondary": "orange",
    "gray": "cool",
    "colors": [
      "red",
      "orange",
      "amber",
      "yellow",
      "lime",
      "green",
      "emerald",
      "teal",
      "cyan",
      "sky",
      "blue",
      "indigo",
      "violet",
      "purple",
      "fuchsia",
      "pink",
      "rose",
      "russian-violet",
      "harvest-gold",
      "taupe-gray",
      "robin-egg-blue",
      "oxford-blue",
      "persian-red",
      "primary",
      "secondary"
    ],
    "strategy": "merge"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/runner/work/joysticktv-office/joysticktv-office/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
