import { defineStore } from 'pinia';
import { useJwt } from '@vueuse/integrations/useJwt';
import type { LoggedInUserQuery } from '#build/graphql-operations';

export type User = NonNullable<LoggedInUserQuery['me']>;

export interface UserState {
    user: User | null;
}

export interface JwtPayload {
  exp: number;
  nbf: number;
  iat: number;
  aud: string;
  user_id: string;
  name: string;
}

export const useUserStore = defineStore('user', () => {
    const dayjs = useDayjs();

    const user = ref<User | null>(null)
    const tokenCount = ref<number>(0)
    const inHolding = ref<boolean>(false)

    // Attempts to log in the user using their username and password. On success, returns the user object.
    async function login(username: string, password: string) {
        const { data } = await useGraphqlMutation('LoginUser', { username, password })

        if (data && data.login.success) {
            if (data.login.admin && data.login.otpEnabled) return true;
            return false;
        } else {
            throw new Error('Invalid username or password.');
        }
    }

    // Authenticate the user using their username and 2fa code. On success, returns the user object.
    async function auth2fa(username: string, code: string) {
        const { data } = await useGraphqlMutation('AuthenticateUser', { username, code });

        if (data && data.authenticate.success) {
          const token = data.authenticate.token!;
          useJwt<JwtPayload>(token);
          const exp = dayjs().endOf('year');
          const cookie = useCookie('graphql:token', {
            expires: exp.toDate(),
            sameSite: 'strict',
          });
          cookie.value = data.authenticate.token!;
        } else {
            throw new Error('Invalid 2fa code.');
        }
    }

    // Log in using an oauth token. On success, returns the user object.
    async function loginWithOauth(newToken: string, newRefreshToken: string) {
        const cookie = useCookie('graphql:token');
        cookie.value = newToken;
    }

    function logOut() {
      const cookie = useCookie('graphql:token');
      user.value = null;
      cookie.value = null;
      tokenCount.value = 0;
      inHolding.value = false;
    }

    const loggedIn = async () => {
      const cookie = useCookie('graphql:token');

      // Check that the cookie exists
      if (!cookie.value) return false;

      try {
        const { data } = await useGraphqlQuery({
          name: 'LoggedInUser',
          fetchOptions: {
            headers: {
              'Authorization': `Bearer ${cookie.value}`,
            }
          }
        });

        if (!data || !data.me) {
          cookie.value = null;
          return false;
        }

        user.value = data.me;
        return true;
      } catch(ex) {
        useBugsnag().notify(ex as any);
        cookie.value = null;
        return false;
      }
  };

    return {
        user,
        tokenCount,
        inHolding,

        loggedIn,
        login,
        auth2fa,
        loginWithOauth,
        logOut,
    }
});
