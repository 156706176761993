import { useUserStore, useAppStore } from "~/store"

export default defineNuxtRouteMiddleware(async (to, _from) => {
  if (to.meta.noAuth) return;
  const appStore = useAppStore();
  await appStore.refreshMediaForReviewCount();
  await appStore.refreshStreamApprovalsForReviewCount();
  await appStore.refreshTagsForReviewCount();
  await appStore.refreshPaymentsForReviewCount();
})
